import React from "react";

const AboutMe = () => (
  <div>
    <p>
      <div>
        I'm a Full-Stack Engineer interested in working on large problems that
        help others. I'm familiar with a variety of languages and cutting edge
        technologies. Passionate about design, engineering, and business and
        creating remarkable user experiences on the web.
      </div>
      <div>
        I work on the entire Web stack (JavaScript, TypeScript, Go, CSS, React,
        GraphQL, SQL, AWS), collaborating closely with other engineers, product
        managers, and designers. I enjoy contributing to the product-development
        process, developing beautiful, intuitive UIs and powerful, scalable
        backend APIs that are well-tested and maintable.
      </div>
    </p>
    <ul>
      <li>testing frameworks: Jest, Cypress</li>
      <li>backend frameworks: symfony</li>
      <li>build tools:Webpack</li>
    </ul>
  </div>
);

export default AboutMe;
